// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getCardListStatus: {
    data: null,
  },
  addCardStatus: {
    data: null,
  },
  updateCardStatus: {
    data: null,
  },
  deleteCardStatus: {
    data: null,
  },
};
