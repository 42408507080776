import React, { useState } from "react";
import { BackButton, CheckBox, InputWithLabel } from "../../components/micro";
import { HouseSearch } from "../../assets/svg/AllSvg";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ConfirmationModel from "../../commons/ConfirmationModel";

const BillingInformation = () => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [values, setValues] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const navigate = useNavigate();

  // Handle Save
  const handleSave = (values) => {
    setIsLoad(true);
    console.log(values);
  };

  return (
    <>
      {/* Page Header */}
      <div>
        <BackButton
          onClick={() => navigate("/billing-subscriptions")}
          className={"mb-3"}
        />
        <div className="flex items-center space-x-3 border-b-2 pb-5 mb-5">
          <h1 className="xl:text-3xl lg:text-2xl sm:text-xl text-base font-ebGaramond">
            My Billing Information
          </h1>
          <div className="max-w-14 w-full">
            <HouseSearch className="w-full" />
          </div>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            address: "",
            city: "",
            state: "",
            zip_code: "",
            email: "",
            phone: "",
            default: "",
          }}
          validationSchema={Yup.object().shape({
            address: Yup.string().required("Please Enter Address"),
            city: Yup.string(),
            state: Yup.string().required("Please Enter State"),
            zip_code: Yup.string().required("Please Enter ZIP Code"),
            email: Yup.string().required("Please Enter Email"),
            default: Yup.boolean(),
            phone: Yup.string().required("Please Enter Phone"),
          })}
          onSubmit={(values) => {
            setValues(values);
            setIsConfirm(true);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Form className="w-full" onSubmit={handleSubmit}>
              <div className="space-y-3 mb-6">
                <InputWithLabel
                  label={"Address*"}
                  type={"text"}
                  className={
                    errors.address && touched.address
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  errors={errors.address && touched.address}
                  errorsText={errors.address}
                />
                <div className="grid grid-cols-3 gap-3">
                  <InputWithLabel
                    label={"City"}
                    type={"text"}
                    className={
                      errors.city && touched.city ? ` input-error ` : ` border `
                    }
                    idFromName="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    errors={errors.city && touched.city}
                    errorsText={errors.city}
                  />
                  <InputWithLabel
                    label={"State*"}
                    type={"text"}
                    className={
                      errors.state && touched.state
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="state"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                    errors={errors.state && touched.state}
                    errorsText={errors.state}
                  />
                  <InputWithLabel
                    label={"ZIP Code*"}
                    type={"number"}
                    className={
                      errors.zip_code && touched.zip_code
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="zip_code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip_code}
                    errors={errors.zip_code && touched.zip_code}
                    errorsText={errors.zip_code}
                  />
                </div>
                <CheckBox
                  idFrom={"default"}
                  label={"Saved as default billing address"}
                  name={"default"}
                  value={values.default}
                  checked={values.default}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className={""}
                />
                <div className="grid grid-cols-2 gap-3">
                  <InputWithLabel
                    label={"Email"}
                    type={"email"}
                    className={
                      errors.email && touched.email
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    errors={errors.email && touched.email}
                    errorsText={errors.email}
                  />
                  <InputWithLabel
                    label={"Phone"}
                    type={"phone"}
                    className={
                      errors.phone && touched.phone
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    errors={errors.phone && touched.phone}
                    errorsText={errors.phone}
                  />
                </div>
              </div>

              <div className="flex gap-4">
                <button
                  type="button"
                  className="btn-outline uppercase"
                  onClick={() => navigate("/billing-subscriptions")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoad}
                  className={`${
                    isLoad ? "btn-outline-disabled" : "btn-theme"
                  } uppercase`}
                >
                  {isLoad ? "Loading" : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Confirmation Model */}
      {isConfirm && (
        <ConfirmationModel
          isLoading={false}
          onClose={() => setIsConfirm(false)}
          onConfirm={() => handleSave(values)}
          confirmText={"Save My Billing Information"}
        />
      )}
    </>
  );
};

export default BillingInformation;
