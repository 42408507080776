import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../helpers/AxiosApi";
import { toast } from "react-toastify";
import initialStates from "./state";

// get Card List
export const getCardList = createAsyncThunk(
  "getCardList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `cards?sort_by=is_default&sort_direction=desc`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Card
export const addCard = createAsyncThunk(
  "addCard",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`cards`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Update Card
export const updateCard = createAsyncThunk(
  "updateCard",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`cards/${id}`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Delete Card
export const deleteCard = createAsyncThunk(
  "deleteCard",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`cards/${id}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const billingSubscriptionsSlice = createSlice({
  name: "billingSubscriptions",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // Upload File
      .addCase(getCardList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCardList.fulfilled, (state, action) => {
        state.loading = false;
        state.getCardListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getCardList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Add Card
      .addCase(addCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCard.fulfilled, (state, action) => {
        state.loading = false;
        state.addCardStatus.data = action.payload;
        state.error = false;
      })
      .addCase(addCard.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Update Card
      .addCase(updateCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCard.fulfilled, (state, action) => {
        state.loading = false;
        state.updateCardStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updateCard.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Delete Card
      .addCase(deleteCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteCardStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deleteCard.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

const { reducer } = billingSubscriptionsSlice;
export default reducer;
