import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Login;
export const LoginAPI = createAsyncThunk(
  "LoginAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`auth/login`, data);
      if (response) {
        sessionStorage.setItem("accessToken", response?.data?.data?.token);
        sessionStorage.setItem("userId", response?.data?.data?.user_id);
        sessionStorage.setItem("profileComplete", `${response?.data?.data}`);
        sessionStorage.setItem(
          "companyList",
          JSON.stringify(response?.data?.data?.companies)
        );
      }
      AxiosApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response?.data?.data?.token}`;
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Register
export const Register = createAsyncThunk(
  "Register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`auth/register`, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Get Profile Data
export const getProfile = createAsyncThunk(
  "getProfile",
  async ({ navigate }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get("profile");
      return response?.data?.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      if (error.response.status === 401) {
        navigate("/login");
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// forget password
export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`auth/forget-password`, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// New password
export const newPassword = createAsyncThunk(
  "newPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`auth/reset-password`, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Admin Update Profile
export const updateProfile = createAsyncThunk(
  "updateProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post("update-profile", data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message[0]);
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // Login;
      .addCase(LoginAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.loginStatus.data = action.payload;
        state.error = false;
      })
      .addCase(LoginAPI.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Register;
      .addCase(Register.pending, (state) => {
        state.loading = true;
      })
      .addCase(Register.fulfilled, (state, action) => {
        state.loading = false;
        state.registerStatus.data = action.payload;
        state.error = false;
      })
      .addCase(Register.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get Profile Data
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.getProfileDataStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getProfile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // forget Password;
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.forgetPasswordStatus.data = action.payload;
        state.error = false;
      })
      .addCase(forgetPassword.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // New Password;
      .addCase(newPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(newPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.newPasswordStatus.data = action.payload;
        state.error = false;
      })
      .addCase(newPassword.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Update Profile;
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.updateProfileStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updateProfile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
  reducers: {
    setAuth: (state) => {
      state.loading = null;
      state.error = null;
    },
  },
});
export const { setAuth } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;
