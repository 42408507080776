import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// get Organisation Details
export const getOrganisationDetails = createAsyncThunk(
  "getOrganisationDetails",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`companies/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Company Users
export const companyUsers = createAsyncThunk(
  "companyUsers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`users`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Remove User
export const removeUser = createAsyncThunk(
  "removeUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`users/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// resendInvitation
export const resendInvitation = createAsyncThunk(
  "resendInvitation",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`resend-invite/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const organisationSlice = createSlice({
  name: "organization",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // Get Organization
      .addCase(getOrganisationDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrganisationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.getOrganisationDetailsStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getOrganisationDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get Company Users
      .addCase(companyUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(companyUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.companyUsersStatus.data = action.payload;
        state.error = false;
      })
      .addCase(companyUsers.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Remove Users
      .addCase(removeUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.loading = false;
        state.removeUserStatus.data = action.payload;
        state.error = false;
      })
      .addCase(removeUser.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Remove Users
      .addCase(resendInvitation.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendInvitation.fulfilled, (state, action) => {
        state.loading = false;
        state.resendInvitationStatus.data = action.payload;
        state.error = false;
      })
      .addCase(resendInvitation.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = organisationSlice;
export default reducer;
