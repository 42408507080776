import React from "react";
import { billingImg } from "../../assets/image";
import { BackButton, Badge } from "../../components/micro";
import { Link, useNavigate } from "react-router-dom";
import { SubscriptionIcon } from "../../assets/svg/AllSvg";

const MySubscription = () => {
  const navigate = useNavigate();

  return (
    <div className="space-y-5">
      <BackButton
        onClick={() => navigate("/billing-subscriptions")}
        className={"mb-3"}
      />
      {/* Page Heading */}
      <div className="flex items-center gap-4 border-b-2 pb-5">
        <div className="w-40">
          <img src={billingImg} alt="billingImg" className="w-full" />
        </div>
        <div>
          <h1 className="xl:text-3xl lg:text-2xl sm:text-xl text-base font-ebGaramond mb-2">
            My Subscriptions
          </h1>
          <p className="sm:text-sm text-xs">Manage your subscriptions</p>
        </div>
      </div>
      {/* My Subscription Details */}
      <div className="card bg-light_secondary_shades flex items-center justify-between !px-6 gap-5">
        <div className="flex items-center gap-5">
          <div className="xl:max-w-14 lg:max-w-12 max-w-10 w-full">
            <SubscriptionIcon className="w-full" />
          </div>
          <div className="space-y-3 w-full">
            <p className="sm:text-xl text-base font-ebGaramond mb-2">
              My Subscription
            </p>
            <div>
              <p className="text-xs text-light_grey">Subscription Type</p>
              <p className="sm:text-sm text-xs">
                Corporate Secretary & Accounting{" "}
              </p>
            </div>
            <div>
              <p className="text-xs text-light_grey">Expired on </p>
              <p className="sm:text-sm text-xs">20/03/2024 </p>
            </div>
          </div>
        </div>
        <button className="btn-theme uppercase text-sm">
          Change my subscription
        </button>
      </div>
      {/* Billing */}
      <div className="card bg-primary_shades_light flex items-center justify-between !px-6 gap-4">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <p className="sm:text-xl text-base font-ebGaramond">Billing</p>
            <Badge
              value={"Auto Renewal"}
              className="bg-green text-white text-xs !py-1"
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div className="border-r-2 border-secondary_grey">
              <p className="text-xs text-light_grey">Expired on</p>
              <p className="sm:text-sm text-xs">20/03/2024</p>
            </div>
            <div>
              <p className="text-xs text-light_grey">Next Deduction Date</p>
              <p className="sm:text-sm text-xs">19/03/2025</p>
            </div>
          </div>
        </div>
        <button className="btn-outline uppercase text-sm">
          Cancel Auto Renewal
        </button>
      </div>
      {/* To Note: */}
      <div className="card bg-primary_shades_light flex items-center justify-between !px-6 gap-4">
        <div>
          <p className="text-xs text-light_grey">Expired on</p>
          <p className="sm:text-sm text-xs">
            For our subscription plans. There will be strictly no refunds
          </p>
          <Link to={"/subscription"} className="text-theme text-xs">
            Our T&C here
          </Link>
        </div>
        <button className="btn-normal uppercase text-sm">
          Cancel Subscription
        </button>
      </div>
    </div>
  );
};

export default MySubscription;
