import React, { useState } from "react";
import Subscription from "./components/Subscription";
import SalesTransactions from "./components/SalesTransactions";

const SubscriptionSalesTransactions = () => {
  const [activeTab, setActiveTab] = useState("My Subscription");
  const tabs = [
    { label: "My Subscription", content: <Subscription /> },
    { label: "Sales Transactions", content: <SalesTransactions /> },
  ];

  return (
    <>
      {/* My Subscription and Sales Transactions */}
      <div className="border p-3 rounded-lg bg-white mb-5">
        <div className="flex border border-theme rounded overflow-hidden">
          {tabs?.map((tab, index) => (
            <button
              key={tab.label}
              className={`sm:py-2 py-1 px-4 uppercase text-center text-theme sm:text-base text-sm sm:font-semibold font-normal w-full transition duration-300 ${
                activeTab === tab.label ? "bg-theme/30" : ""
              } ${index === 0 && "border-r border-theme"}`}
              onClick={() => setActiveTab(tab.label)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      <div>{tabs?.map((tab) => activeTab === tab.label && tab.content)}</div>
    </>
  );
};

export default SubscriptionSalesTransactions;
