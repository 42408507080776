import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createTicket, getTicketsList } from "../../../redux/ticket/slice";
import {
  Close,
  ImageIcon,
  PDFDoc,
  Redo,
  RippleLoaderSVG,
  Undo,
} from "../../../assets/svg/AllSvg";
import Action from "../../../commons/Action";
import ConfirmationModel from "../../../commons/ConfirmationModel";
import { uploadFile } from "../../../redux/uploadFile/slice";
import "react-quill/dist/quill.snow.css";
import { InputWithLabel } from "../../../components/micro";
import { InputError } from "../../../commons/MicroComponents";
import { formats, modules } from "../../../commons/Editor";

const CreateQuickTicket = ({
  setCreateQuickTicketModel,
  searchValue,
  selectedPriority,
  selectedPeriod,
  pageCount,
  pageLimit,
  status,
}) => {
  const [maxCharacter, setMaxCharacter] = useState(0);
  const [confirmCreateTicket, setConfirmCreateTicket] = useState(false);
  const [documentArray, setDocumentArray] = useState([]);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [createTicketData, setCreateTicketData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const quillRef = useRef(null);

  // Submit Ticket
  const handleConfirmCreateTicket = () => {
    const payload = {
      title: createTicketData?.title,
      message: createTicketData?.message,
      attachments: documentArray?.map((e) => e.id),
    };
    setIsLoading(true);
    dispatch(createTicket(payload)).then((res) => {
      setIsLoading(false);
      setConfirmCreateTicket(false);
      if (res.type === "createTicket/fulfilled") {
        dispatch(
          getTicketsList({
            search: searchValue,
            priority: selectedPriority?.value ?? "",
            period: selectedPeriod?.value ?? "",
            pageCount: pageCount,
            pageLimit: pageLimit,
            status: status,
          })
        );
        setCreateQuickTicketModel(false);
      }
    });
  };

  // Get File URL Upload Editor
  const handleUpload = (e) => {
    const file = e.target.files[0];
    const type = file?.type;
    const formData = new FormData();
    formData.append("type", "ticket");
    formData.append("file", file);
    formData.append(
      "document_type",
      type === "application/pdf" ? "doc" : "img"
    );
    setIsFileUpload(true);
    dispatch(uploadFile(formData)).then((res) => {
      setIsFileUpload(false);
      if (res.type === "uploadFile/fulfilled") {
        setDocumentArray((prevArray) => [...prevArray, res.payload.data]);
        quillRef.current?.getEditor();
      }
    });
  };

  // Add Undo and Redo buttons to the toolbar
  const CustomToolbar = () => (
    <div
      id="toolbar"
      className="flex justify-between items-center absolute max-w-[calc(100%-75px)] w-full left-20 right-0 pl-3 pr-5 h-10"
    >
      <div className="flex items-center space-x-2">
        <div className="relative mt-1">
          <ImageIcon width="15" height="15" className="ml-2" />
          <input
            type="file"
            accept=".jpeg,.jpg,.png,.pdf"
            className="absolute top-0 left-0 w-full h-full opacity-0"
            onChange={handleUpload}
          />
        </div>
      </div>
      <div className="flex items-center gap-3">
        <button
          className="ql-undo"
          type="button"
          onClick={() => quillRef.current.getEditor().history.undo()}
        >
          <Undo />
        </button>
        <button
          className="ql-redo"
          type="button"
          onClick={() => quillRef.current.getEditor().history.redo()}
        >
          <Redo />
        </button>
      </div>
    </div>
  );
  return (
    <div
      className="model"
      onClick={() => {
        setCreateQuickTicketModel(false);
      }}
    >
      <div
        className="bg-white drop-shadow-lg rounded-lg w-full max-w-3xl min-h-36 max-h-[650px] overflow-y-auto py-5 px-6 lg:mx-auto mx-5 relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className="text-theme w-full mb-1 sm:text-base text-sm">
          Create Quick Ticket
        </p>
        <p className="sm:text-sm text-xs text-light_grey mb-5">
          Write and address new queries and issues
        </p>
        <Close
          className="cursor-pointer absolute top-5 right-5"
          onClick={() => {
            setCreateQuickTicketModel(false);
          }}
        />
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: "",
              message: "",
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .max(250)
                .required("Please Enter Ticket Title"),
              message: Yup.string().required("Please Enter Ticket Issue"),
            })}
            onSubmit={(values) => {
              setCreateTicketData(values);
              setConfirmCreateTicket(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="w-full space-y-3" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-5 border-b border-secondary_grey pb-3">
                  {/* Ticket Title */}
                  <div className="col-span-2">
                    <InputWithLabel
                      label={"Ticket Title"}
                      type={"text"}
                      className={
                        errors.title && touched.title
                          ? ` input-error `
                          : ` border border-secondary_grey`
                      }
                      idFromName="title"
                      onChange={(e) => {
                        handleChange(e);
                        setMaxCharacter(e.target.value.length);
                      }}
                      onBlur={handleBlur}
                      value={values.title}
                      errors={errors.title && touched.title}
                      errorsText={errors.title}
                    />
                    <span className="text-light_grey text-xs mt-0.5">
                      Max Character ({maxCharacter}/250)
                    </span>
                  </div>
                  {/* Message Editor */}
                  <div className="col-span-2 relative">
                    <CustomToolbar />
                    <ReactQuill
                      id="message"
                      name="message"
                      onChange={handleChange("message")}
                      theme="snow"
                      value={values.message}
                      modules={modules}
                      formats={formats}
                      bounds={"#root"}
                      ref={quillRef}
                      placeholder={"Message"}
                    />
                    {errors.message && touched.message ? (
                      <InputError errorTitle={errors.message} />
                    ) : null}
                  </div>
                  {isFileUpload ? (
                    <RippleLoaderSVG />
                  ) : (
                    <>
                      {documentArray?.length ? (
                        <div className="col-span-2 flex items-start border border-secondary_grey flex-wrap rounded px-4 py-3 gap-3 relative">
                          <label className="text-light_grey bg-white text-base px-2 absolute pointer-events-none scale-75 -translate-y-6 left-1">
                            Media
                          </label>
                          {documentArray?.map((element) => {
                            return (
                              <>
                                {element?.document_type === "doc" ? (
                                  <div
                                    className={
                                      "card bg-light_secondary flex justify-between items-center max-w-60 w-full relative"
                                    }
                                  >
                                    <div className="flex items-center w-full">
                                      <PDFDoc className="mr-3" />
                                      <p className="text-black font-normal max-w-full text-sm truncate">
                                        {element?.name}
                                      </p>
                                    </div>
                                    <button type="button">
                                      <Action
                                        optionList={
                                          <>
                                            <a
                                              href={element?.url}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                            >
                                              View
                                            </a>
                                            <a
                                              href={element?.url}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                            >
                                              Download
                                            </a>
                                          </>
                                        }
                                      />
                                    </button>
                                  </div>
                                ) : (
                                  <div className="relative max-w-24 h-24 w-full rounded-md border-2">
                                    <button
                                      type="button"
                                      className="absolute bg-white rounded-md top-1 right-1"
                                    >
                                      <Action
                                        className="!px-2"
                                        optionList={
                                          <>
                                            <a
                                              href={element?.url}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                            >
                                              View
                                            </a>
                                            <a
                                              href={element?.url}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                            >
                                              Download
                                            </a>
                                          </>
                                        }
                                      />
                                    </button>
                                    <img
                                      src={element?.url}
                                      alt="img"
                                      className="w-full h-full rounded-md object-cover"
                                    />
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>

                <div className="flex justify-end gap-4 w-full">
                  <button
                    type="button"
                    className="btn-outline uppercase"
                    onClick={() => {
                      setCreateQuickTicketModel(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn-theme uppercase">
                    Add
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {/* Confirm Create Ticket */}
      {confirmCreateTicket && (
        <ConfirmationModel
          isLoading={isLoading}
          onConfirm={() => handleConfirmCreateTicket()}
          onClose={() => setConfirmCreateTicket(false)}
          confirmText={"Create Ticket"}
        />
      )}
    </div>
  );
};

export default CreateQuickTicket;
