import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { InputError } from "../../commons/MicroComponents";
import RippleLoader from "../../commons/RippleLoader";
import { getCountryData } from "../../redux/address/slice";
import { Eye, EyeSlash, MailBox } from "../../assets/svg/AllSvg";
import { InputWithLabel } from "../../components/micro";
import ReactSelect from "react-select";
import { getProfile, updateProfile } from "../../redux/auth/slice";
import { profileImg } from "../../assets/image";
import ConfirmationModal from "../../commons/ConfirmModal";
import ButtonLoader from "../../commons/ButtonLoader";

const Profile = () => {
  const [countryArray, setCountryArray] = useState([]);
  const [passwordType, setPasswordType] = useState("password");
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileValues, setProfileValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getProfileData, profileLoading, countryData, countryLoading } =
    useSelector((store) => ({
      getProfileData: store?.authData?.getProfileDataStatus?.data,
      profileLoading: store?.authData?.loading,
      countryData: store?.addressData?.getCountryDataStatus?.data,
      countryLoading: store?.addressData?.getCountryDataStatus?.loading,
    }));

  useEffect(() => {
    setIsLoading(true);
    dispatch(getProfile({ navigate }));
    dispatch(getCountryData());
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({
        ...x,
        label: `+ ${x?.phonecode}`,
      }));
      setCountryArray(data);
    }
  }, [countryData]);

  const countryValueGet = {
    label: getProfileData?.country?.phonecode,
    ...getProfileData?.country,
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  return (
    <>
      {profileLoading ? (
        <RippleLoader />
      ) : (
        <section>
          <div className="flex items-center space-x-5 border-b border-secondary_grey pb-4 mb-6">
            <img src={profileImg} alt="img" />
            <h6 className="font-ebGaramond font-semibold text-2xl ">
              My Profile
            </h6>
          </div>
          {/* User Profile */}
          <Formik
            enableReinitialize={true}
            initialValues={{
              full_name: getProfileData?.full_name ?? "",
              phone: getProfileData?.phone ?? "",
              email: getProfileData?.email ?? "",
              country: getProfileData?.country?.id ? countryValueGet : "",
              country_id: getProfileData?.country_id ?? "",
              password: getProfileData?.password ?? "",
            }}
            validationSchema={Yup.object().shape({
              full_name: Yup.string().min(3).required("Please Enter Name"),
              phone: Yup.string()
                .required("Please Enter Mobile")
                .max(
                  13,
                  "Mobile is too long - should be 10 characters maximum."
                ),
              country: Yup.object().required("Please Select Country"),
              email: Yup.string().min(3).required("Please Enter E-mail"),
              password: getProfileData
                ? Yup.string()
                    .min(
                      8,
                      "Password is too short - should be 8 characters minimum."
                    )
                    .matches(
                      /[a-zA-Z]/,
                      "Password can only contain latin letters."
                    )
                : Yup.string()
                    .required("Please Enter Password")
                    .min(
                      8,
                      "Password is too short - should be 8 characters minimum."
                    )
                    .matches(
                      /[a-zA-Z]/,
                      "Password can only contain latin letters."
                    ),
            })}
            onSubmit={(values) => {
              setIsModalOpen(true);
              setProfileValues(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="w-full bg-white border rounded space-y-6 py-5 px-6">
                  {/* Name */}
                  <InputWithLabel
                    className={`${
                      isEditing ? "text-gray-900" : "text-gray-400"
                    } ${
                      errors.phone && touched.phone ? "input-error" : "border"
                    }`}
                    idFromName="full_name"
                    label="Name"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.full_name}
                    errors={errors.full_name && touched.full_name}
                    errorsText={errors.full_name}
                    disabled={!isEditing}
                  />
                  {/* Mobile */}
                  <div className="flex gap-5">
                    {/* Country */}
                    <div className="max-w-40 w-full">
                      <div
                        className={`${
                          errors.country && touched.country
                            ? ` input-error `
                            : ""
                        } relative bg-white transition duration-300 rounded plus-number-inputs`}
                      >
                        <ReactSelect
                          className={"text-black p-0"}
                          id="country"
                          placeholder="Country"
                          options={countryArray}
                          isLoading={countryLoading}
                          getOptionValue={(countryArray) =>
                            countryArray.phonecode
                          }
                          value={
                            countryArray?.length > 0 &&
                            countryArray?.find((op) => {
                              return op.label === values.country.label;
                            })
                          }
                          onChange={(e) => {
                            setFieldValue("country", e);
                            setFieldValue("country_id", e?.id);
                          }}
                          isDisabled={!isEditing}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: `1px solid ${
                                errors.country && touched.country
                                  ? "red"
                                  : "#0000003B"
                              }`,
                              borderRadius: "0.25rem",
                              padding: "5px 4px",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #0000003B",
                              },
                            }),
                          }}
                        />
                        <label
                          htmlFor="country"
                          className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Country
                        </label>
                      </div>
                      {errors.country && touched.country ? (
                        <InputError errorTitle={errors.country} />
                      ) : null}
                    </div>
                    {/* Mobile */}
                    <div className="w-full">
                      <InputWithLabel
                        className={`${
                          isEditing ? "text-gray-900" : "text-gray-400"
                        } ${
                          errors.phone && touched.phone
                            ? "input-error"
                            : "border"
                        }`}
                        idFromName="phone"
                        label="Mobile"
                        type={"number"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        errors={errors.phone && touched.phone}
                        errorsText={errors.phone}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                  {/* Email */}
                  <div>
                    <div
                      className={`${
                        errors.email && touched.email
                          ? ` input-error `
                          : ` border `
                      } relative bg-white border-secondary_grey transition duration-300 rounded`}
                    >
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className={`block px-2.5 py-3 pl-11 w-full text-sm error-forms ${
                          isEditing ? "text-gray-900" : "text-gray-400"
                        } bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                        placeholder={"myemail@gmail.com"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!isEditing}
                        value={values.email}
                      />
                      <MailBox className="absolute top-1/2 left-3 -translate-y-1/2" />
                      <label
                        htmlFor="email"
                        className="absolute top-0 left-1 -translate-y-2.5 text-light_grey pointer-events-none text-base scale-75 px-1 bg-white"
                      >
                        Email
                      </label>
                    </div>
                    {errors.email && touched.email ? (
                      <InputError errorTitle={errors.email} />
                    ) : null}
                  </div>
                  {/* Password */}
                  <div>
                    <div
                      className={`${
                        errors.password && touched.password
                          ? ` input-error `
                          : ` border `
                      } relative bg-white border-secondary_grey transition duration-300 rounded`}
                    >
                      <input
                        type={passwordType}
                        id="password"
                        name="password"
                        className="block px-2.5 py-3 pl-11 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder={"Example@123"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        disabled={!isEditing}
                      />
                      <p
                        className="absolute top-1/2 left-3 -translate-y-1/2"
                        onClick={() => {
                          setPasswordType(
                            passwordType === "password" ? "text" : "password"
                          );
                        }}
                      >
                        {passwordType === "password" ? <EyeSlash /> : <Eye />}
                      </p>
                      <label
                        htmlFor="password"
                        className="absolute top-0 left-1 -translate-y-2.5 text-light_grey pointer-events-none text-base scale-75 px-1 bg-white"
                      >
                        Password
                      </label>
                    </div>
                    {errors.password && touched.password ? (
                      <InputError errorTitle={errors.password} />
                    ) : null}
                  </div>
                  {/* Buttons */}
                  <div className="flex justify-end mt-3">
                    {isEditing ? (
                      <>
                        <button
                          type="button"
                          className="btn-cancel border border-theme p-2 mr-4 rounded-lg"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn-theme uppercase rounded-lg"
                        >
                          {isLoading ? <ButtonLoader /> : <span>Save</span>}
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="btn-theme uppercase"
                        onClick={handleEditClick}
                      >
                        {isLoading ? <ButtonLoader /> : <span>Edit</span>}
                      </button>
                    )}
                  </div>
                </div>
                {isModalOpen && (
                  <ConfirmationModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    isLoading={isLoading}
                    onConfirm={() => {
                      dispatch(updateProfile(profileValues)).then((res) => {
                        if (res.type === "updateProfile/fulfilled") {
                          dispatch(getProfile({ navigate }));
                          setIsModalOpen(false);
                        }
                      });
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </section>
      )}
    </>
  );
};

export default Profile;
