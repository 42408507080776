import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { CheckBox, InputWithLabel } from "../../../components/micro";
import ConfirmationModel from "../../../commons/ConfirmationModel";
import { useDispatch } from "react-redux";
import {
  addCard,
  getCardList,
} from "../../../redux/billingSubscriptions/slice";

const AddNewCard = ({ onClose, cardDetails }) => {
  const [isLoad, setIsLoad] = useState(false);
  const [cardValues, setCardValues] = useState({});
  const [isConfirm, setIsConfirm] = useState(false);
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear(); // Get the current year
  const currentTwoDigitYear = currentYear % 100; // Extract last two digits of the year

  // Save Card
  const handleSave = (values) => {
    setIsLoad(true);
    dispatch(
      addCard(cardDetails?.id ? { id: cardDetails?.id, ...values } : values)
    ).then((res) => {
      setIsLoad(false);
      setIsConfirm(false);
      if (res.type === "addCard/fulfilled") {
        dispatch(getCardList());
        onClose();
      }
    });
    setIsLoad(true);
  };

  // handle Card Number Change
  const handleCardNumberChange = (e, setFieldValue) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    const formattedValue = value.replace(/(\d{4})(?=\d)/g, "$1 "); // Add space after every 4 digits
    setFieldValue("card_number", formattedValue); // Update Formik value
  };
  return (
    <>
      <div className="model" onClick={onClose}>
        <div
          className="bg-white drop-shadow-lg rounded-lg w-full max-w-2xl max-h-[700px] overflow-y-auto min-h-36 py-5 px-6 m-5 relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="sm:text-2xl text-base font-ebGaramond border-b border-secondary_grey capitalize w-full pb-3 mb-4">
            {cardDetails?.id ? "Edit Card" : "Add new card"}
          </h2>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: cardDetails?.name ?? "",
              card_number: cardDetails?.number ?? "",
              expiration_month: cardDetails?.month ?? "",
              expiration_year: cardDetails?.year ?? "",
              cvc: cardDetails?.code ?? "",
              is_default: cardDetails?.is_default === 1 ? true : false,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Please Enter Card Name"),
              card_number: Yup.string()
                .matches(/^(\d{4} ?){0,4}$/, "Invalid card number format") // Validates space after every 4 digits
                .required("Card number is required")
                .test(
                  "card-length",
                  "Card number must be 16 digits",
                  (value) => value && value.replace(/\s/g, "").length === 16 // Check total length (excluding spaces)
                ),
              expiration_month: Yup.string()
                .required("Expiry month is required") // Ensure it's not empty
                .test(
                  "is-valid-month",
                  "Invalid month (must be 01-12)",
                  (value) => {
                    if (!value) return false; // If no value, fail the test
                    const month = parseInt(value, 10); // Convert the value to an integer
                    return month >= 1 && month <= 12; // Check if it's within 1 to 12
                  }
                ),
              expiration_year: Yup.string()
                .matches(/^\d{2}$/, "Invalid year format (must be YY)") // Ensure two digits
                .required("Expiry year is required") // Required field
                .test(
                  "is-future-year",
                  "Expiry year must not be in the past",
                  (value) => {
                    if (!value) return false; // If no value, fail the test
                    return parseInt(value, 10) >= currentTwoDigitYear; // Compare with current year
                  }
                ),
              cvc: Yup.string().required("Please Enter Security Code"),
              is_default: Yup.boolean(),
            })}
            onSubmit={(values) => {
              setCardValues(values);
              setIsConfirm(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="w-full" onSubmit={handleSubmit}>
                <div className="space-y-3 mb-6">
                  <InputWithLabel
                    label={"Name on Card*"}
                    type={"text"}
                    className={
                      errors.name && touched.name ? ` input-error ` : ` border `
                    }
                    idFromName="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    errors={errors.name && touched.name}
                    errorsText={errors.name}
                  />
                  <InputWithLabel
                    label={"Card Number*"}
                    type={"text"}
                    className={
                      errors.card_number && touched.card_number
                        ? ` input-error `
                        : ` border `
                    }
                    maxLength={19}
                    idFromName="card_number"
                    onChange={(e) => handleCardNumberChange(e, setFieldValue)}
                    onBlur={handleBlur}
                    value={values.card_number}
                    errors={errors.card_number && touched.card_number}
                    errorsText={errors.card_number}
                  />
                  <div className="grid sm:grid-cols-3 gap-3">
                    <InputWithLabel
                      label={"Expiry Month*"}
                      type={"text"}
                      className={
                        errors.expiration_month && touched.expiration_month
                          ? ` input-error `
                          : ` border `
                      }
                      maxLength={2}
                      idFromName="expiration_month"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expiration_month}
                      errors={
                        errors.expiration_month && touched.expiration_month
                      }
                      errorsText={errors.expiration_month}
                    />
                    <InputWithLabel
                      label={"Expiry Year*"}
                      type={"text"}
                      className={
                        errors.expiration_year && touched.expiration_year
                          ? ` input-error `
                          : ` border `
                      }
                      maxLength={2}
                      idFromName="expiration_year"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expiration_year}
                      errors={errors.expiration_year && touched.expiration_year}
                      errorsText={errors.expiration_year}
                    />
                    <InputWithLabel
                      label={"Security Code*"}
                      type={"text"}
                      className={
                        errors.cvc && touched.cvc ? ` input-error ` : ` border `
                      }
                      idFromName="cvc"
                      onChange={handleChange}
                      maxLength={3}
                      placeholder="YY"
                      onBlur={handleBlur}
                      value={values.cvc}
                      errors={errors.cvc && touched.cvc}
                      errorsText={errors.cvc}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="w-full">
                    <CheckBox
                      idFrom={"is_default"}
                      label={"Saved as default Card"}
                      name={"is_default"}
                      value={values.is_default}
                      checked={values.is_default}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={""}
                    />
                  </div>
                  <div className="flex justify-end gap-4 w-full">
                    <button
                      type="button"
                      className="btn-outline uppercase"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isLoad}
                      className={`${
                        isLoad ? "btn-outline-disabled" : "btn-theme"
                      } uppercase`}
                    >
                      {isLoad ? "Loading" : "Save"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {isConfirm && (
        <ConfirmationModel
          isLoading={isLoad}
          onClose={() => setIsConfirm(false)}
          onConfirm={() => handleSave(cardValues)}
          confirmText={"Save My Payment Method"}
        />
      )}
    </>
  );
};

export default AddNewCard;
