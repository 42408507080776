import React, { useEffect, useState } from "react";
import CompanyInfo from "./CompanyInfo";
import "./company.css";
import CompanyAddress from "./CompanyAddress";
import { StepperImg } from "../../assets/image";
import Accounting from "./Accounting";
import Payment from "./Payment";
import PaymentLast from "./PaymentLast";
import { CheckCircle } from "../../assets/svg/AllSvg";
import { Provider } from "./CompanyContext";
import { useLocation } from "react-router-dom";
import DirectorsShareholders from "./directorsShareholders/DirectorsShareholders";
import { getOrganisationDetails } from "../../redux/organisation/slice";
import { useDispatch, useSelector } from "react-redux";
import { companyData } from "../../redux/companies/slice";

const NewCompany = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [companyId, setCompanyId] = useState(undefined);

  const location = useLocation();
  const dispatch = useDispatch();
  const type = location?.state?.companyType;

  // Selector
  const { organisationDetails, loading, CompanyData } = useSelector(
    (store) => ({
      organisationDetails:
        store?.organisationData?.getOrganisationDetailsStatus?.data?.data,
      loading: store?.organisationData?.loading,
      // Company Data
      CompanyData: store?.companiesData?.companyDataStatus?.data?.data,
    })
  );

  // Get APIs
  useEffect(() => {
    if (companyId) {
      dispatch(getOrganisationDetails(companyId));
    }
  }, [companyId]);

  useEffect(() => {
    dispatch(companyData());
  }, []);

  const tabLabel = [
    { value: "company_information", label: "Company Information" },
    { value: "company_address", label: "Company Address" },
    { value: "directors_shareholders", label: "Directors & Shareholders" },
    { value: "employment_support", label: "Employment Support" },
    { value: "payment", label: "Payment" },
  ];

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const renderStep = (step) => {
    switch (tabLabel[step]?.value) {
      case "company_information":
        return (
          <CompanyInfo
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
            type={type}
            loading={loading}
            organisationDetails={companyId ? organisationDetails : ""}
            CompanyData={CompanyData}
          />
        );
      case "company_address":
        return (
          <CompanyAddress
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
            loading={loading}
            addresses={companyId ? organisationDetails?.addresses ?? [] : []}
            business_registered_address_fee={
              CompanyData?.other?.business_registered_address_fee ?? "-"
            }
          />
        );
      case "directors_shareholders":
        return (
          <DirectorsShareholders
            companyId={companyId}
            shareholders={
              companyId ? organisationDetails?.shareholders ?? [] : []
            }
            resident_directors={
              companyId ? organisationDetails?.resident_directors ?? [] : []
            }
            nominee_directors={
              companyId ? organisationDetails?.nominee_directors ?? [] : []
            }
            nominee_director_deposits={
              companyId
                ? organisationDetails?.nominee_director_deposits ?? ""
                : ""
            }
            nominee_director_durations={
              companyId
                ? organisationDetails?.nominee_director_durations ?? ""
                : ""
            }
            loading={loading}
          />
        );
      case "employment_support":
        return (
          <Accounting
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
            no_of_eps={companyId ? organisationDetails?.no_of_eps ?? "" : ""}
            no_of_locs={companyId ? organisationDetails?.no_of_locs ?? "" : ""}
            loading={loading}
          />
        );
      case "payment":
        return (
          <Payment
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
            no_of_eps={companyId ? organisationDetails?.no_of_eps ?? "" : ""}
            no_of_locs={companyId ? organisationDetails?.no_of_locs ?? "" : ""}
            loading={loading}
          />
        );
      case "confirmation":
        return (
          <PaymentLast
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
            loading={loading}
          />
        );
      default:
        return null;
    }
  };

  const next = () => {
    if (currentStep < tabLabel.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="sm:flex">
      {/* stepper */}
      <Provider value={{ next, prev }}>
        <div className="p-4 sm:mb-0 mb-5">
          <img
            className="w-30 h-30 relative bottom-1 right-4"
            src={StepperImg}
          />
          <div className="stepper xl:space-y-10 space-y-8">
            {tabLabel?.map((step, index) => (
              <div
                key={index}
                className="flex items-center space-x-3 sm:text-left text-center"
              >
                <span
                  className={`xl:max-w-8 max-w-6 xl:min-w-8 min-w-6 w-full xl:h-8 h-6 rounded-full flex justify-center items-center bg-gray-400 text-white xl:text-base sm:text-sm text-xs ${
                    index < currentStep ? "!bg-white text-green-700" : ""
                  }${index === currentStep ? "!bg-theme" : ""}`}
                >
                  {index < currentStep ? (
                    <CheckCircle
                      className="xl:w-8 sm:w-6 w-5 xl:h-8 sm:h-6 h-6"
                      pathClass={"fill-green-700"}
                    />
                  ) : (
                    index + 1
                  )}
                </span>
                <p
                  className={`xl:text-base text-sm ${
                    index < currentStep ? "text-green-700" : ""
                  } ${index === currentStep ? "font-semibold" : ""}`}
                >
                  {step.label}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* stepper */}
        <div className="w-full">{renderStep(currentStep)}</div>
      </Provider>
    </div>
  );
};

export default NewCompany;
