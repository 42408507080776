import React, { useState, useEffect } from "react";
import { BackButton, InputWithLabel } from "../../../components/micro";
import { headChat } from "../../../assets/image";
import { BusinessBuilding, HouseSearch } from "../../../assets/svg/AllSvg";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getCountryData } from "../../../redux/address/slice";
import FileUploadChoose from "../../../commons/FileUploadChoose";

import { useNavigate } from "react-router-dom";

const RegisteredAddress = () => {
  return (
    <div className="flex space-x-5">
      <HouseSearch />
      <div>
        <span className="px-2.5 py-1 text-sm bg-lightGreen text-white inline-block rounded-full mb-2">
          Your EiBiz Registered Address
        </span>
        <p className="text-2xl font-ebGaramond mb-2">
          Your Registered Office Address
        </p>
        <div className="text-gray-400">
          <p className="text-sm">Address Line 1:</p>
          <p>Blk 123 Walk the Lane #01-11</p>
          <p className="text-sm">Address Line 2:</p>
          <p className="text-sm">Postal Code:</p>
          <p>123456</p>
          <p className="text-sm">Country:</p>
          <p>Singapore</p>
        </div>
      </div>
    </div>
  );
};

const NewRegisteredOfficeAddress = ({ onFormComplete }) => {
  const dispatch = useDispatch();
  const [countryArray, setCountryArray] = useState([]);
  const [formValues, setFormValues] = useState({
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    country: null,
  });

  const { countryData, countryLoading } = useSelector((store) => ({
    countryData: store?.addressData?.getCountryDataStatus?.data,
    countryLoading: store?.addressData?.getCountryDataStatus?.loading,
  }));

  useEffect(() => {
    dispatch(getCountryData());
  }, [dispatch]);

  useEffect(() => {
    if (countryData?.data) {
      const data = countryData?.data?.map((x) => ({
        ...x,
        label: `+ ${x?.phonecode}`,
      }));
      setCountryArray(data);
    }
  }, [countryData]);

  useEffect(() => {
    onFormComplete(
      formValues.addressLine1 &&
        formValues.addressLine2 &&
        formValues.postalCode &&
        formValues.country
    );
  }, [formValues, onFormComplete]);

  const handleInputChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormValues((prev) => ({
      ...prev,
      country: selectedOption,
    }));
  };

  return (
    <div className="space-y-3 border-b border-secondary_grey pb-3">
      <p>New Registered Office Address</p>
      <ReactSelect
        className={"p-0"}
        id="address_type"
        placeholder="Address Type"
        options={[
          { value: "home", label: "Home" },
          { value: "office", label: "Office" },
        ]}
        isLoading={false}
        styles={{
          control: (base) => ({
            ...base,
            border: `1px solid #cdddeb`,
            borderRadius: "0.3rem",
            paddingTop: "4px",
            paddingBottom: "4px",
            boxShadow: "none",
            "&:hover": {
              border: "1px solid #cdddeb",
            },
          }),
        }}
      />
      <InputWithLabel
        label={"Address Line 1"}
        type={"text"}
        id={"addressLine1"}
        value={formValues.addressLine1}
        onChange={handleInputChange}
        className={"border"}
      />
      <InputWithLabel
        label={"Address Line 2"}
        type={"text"}
        id={"addressLine2"}
        value={formValues.addressLine2}
        onChange={handleInputChange}
        className={"border"}
      />
      <div className="grid grid-cols-2 gap-5">
        <InputWithLabel
          label={"Postal Code"}
          type={"text"}
          id={"postalCode"}
          value={formValues.postalCode}
          onChange={handleInputChange}
          className={"border"}
        />
        <ReactSelect
          className={"p-0"}
          id="Country"
          placeholder="Country"
          options={countryArray}
          value={formValues.country}
          onChange={handleSelectChange}
          isLoading={countryLoading}
          getOptionValue={(option) => option.name}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "4px",
              paddingBottom: "4px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
      </div>
    </div>
  );
};

const ChangeCompanyAddress = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const navigate = useNavigate();

  const tabs = [
    {
      text: "Use EiBiz’s registered address & Digital Mailroom Service",
      id: 0,
      content: <RegisteredAddress />,
      price: 400,
    },
    {
      text: "Use your own company address",
      link: "Eligibility Criteria",
      id: 1,
      content: (
        <NewRegisteredOfficeAddress onFormComplete={setIsFormComplete} />
      ),
    },
  ];

  return (
    <div className="space-y-5">
      <BackButton onClick={() => navigate("/service-request")} />
      <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
        <img src={headChat} alt="img" />
        <div className="space-y-2 font-ebGaramond">
          <p className="text-xl">We see that you will need a request on:</p>
          <p className="text-3xl">Change of Company Address</p>
        </div>
      </div>
      <div className="space-y-3 border-b border-secondary_grey pb-3">
        <p className="text-2xl font-ebGaramond">
          Let us guide you through the process:
        </p>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>1.</span>
          <p>Tell us about your new company address</p>
        </div>
        {activeTab === 1 && (
          <div className="flex space-x-5">
            <HouseSearch />
            <div>
              <p className="text-2xl font-ebGaramond mb-2">
                Your Registered Office Address
              </p>
              <div className="text-gray-400">
                <p className="text-sm">Address Line 1:</p>
                <p>Blk 123 Walk the Lane #01-11</p>
                <p className="text-sm">Address Line 2:</p>
                <p className="text-sm">Postal Code:</p>
                <p>123456</p>
                <p className="text-sm">Country:</p>
                <p>Singapore</p>
              </div>
            </div>
          </div>
        )}
        {/* tab head */}
        <div className="grid grid-cols-2 gap-5">
          {tabs.map((element) => (
            <div
              key={element.id}
              className={`p-4 ${
                activeTab === element.id
                  ? "bg-light_primary_shades"
                  : "bg-white"
              } rounded-lg border-2 space-y-3`}
              onClick={() => setActiveTab(element.id)}
            >
              <div className="flex space-x-5">
                <BusinessBuilding />
                <div className="space-y-3 w-full">
                  <p>{element.text}</p>
                  {element.price && (
                    <div className="flex justify-between space-x-1 bg-light_primary_shades px-4 py-3">
                      <p>Price</p>
                      <div>
                        <p className="text-sm text-gray-400">Annual Fees</p>
                        <p>$400</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {element.link && (
                <button className="text-theme border-b border-theme">
                  {element.link}
                </button>
              )}
            </div>
          ))}
        </div>
        {/* tab content */}
        {tabs.map((element) => activeTab === element.id && element.content)}
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>2.</span>
          <p>We will prepare the relevant resolution</p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>3.</span>
          <p>Obtain signed resolution</p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>4.</span>
          <p>
            We will lodge the changes to ACRA for the change of Company Name
          </p>
        </div>
        <p className="text-sm">
          Once the request is opened, you can easily manage it over at the
          “On-Going” service page.
        </p>
      </div>
      <div className="flex items-center justify-between space-x-1 bg-light_primary_shades rounded-lg px-4 py-3">
        <p className="font-semibold">Total Payable:</p>
        <div>
          <p className="text-xs">1/3 used</p>
          {activeTab === 1 ? <p>Free</p> : <p>$400</p>}
        </div>
      </div>
      <div className="box !rounded-xl flex items-center justify-between">
        <button
          className={`btn-theme uppercase ${
            !isFormComplete && activeTab === 1
              ? "cursor-not-allowed opacity-50"
              : ""
          }`}
          onClick={() => {
            if (activeTab === 1 && isFormComplete) {
              navigate("/approver");
            } else if (activeTab === 0) {
              navigate("/approver");
            }
          }}
          disabled={!isFormComplete && activeTab === 1}
        >
          Proceed with request
        </button>
        <div>
          <p className="text-xs">Total:</p>
          {activeTab === 1 ? <p>Free</p> : <p>$400</p>}
        </div>
      </div>
    </div>
  );
};

export default ChangeCompanyAddress;
