import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  addCompany,
  welcome,
  Registration,
  Question,
  Notification,
  Check,
  Page,
} from "../../assets/image";
import "../auth/Auth.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "./dashboard.css";
import AddCompany from "../modal/AddCompany";
import { InfoCircle, User } from "iconsax-react";
import ChatList from "../chat/Chat";
import { Close } from "../../assets/svg/AllSvg";
import { format } from "date-fns";
import { getCompany } from "../../redux/companies/slice";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css/pagination";
import RippleLoader from "../../commons/RippleLoader";

const Dashboard = () => {
  const location = useLocation();
  const [showMessage, setShowMessage] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [addEditRolePopup, setAddCompanyPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const hasSeenPopup = sessionStorage.getItem("isCompanyPopup");
  const hasSeenPopup1 = sessionStorage.getItem("isCompanyPopup1");
  const dispatch = useDispatch();
  const swiperRef = useRef(null);

  useEffect(() => {
    const companyFromState = location.state?.selectedCompany;

    if (companyFromState) {
      if (companyFromState === null) {
        localStorage.removeItem("selectedCompany");
        setSelectedCompany(null);
      } else {
        setSelectedCompany(companyFromState);
      }
    } else {
      const storedCompany = localStorage.getItem("selectedCompany");
      if (storedCompany) {
        setSelectedCompany(JSON.parse(storedCompany));
      } else {
        setSelectedCompany(null);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (GetCompany?.length === 0 && !hasSeenPopup && hasSeenPopup1 === false) {
      setAddCompanyPopup(true);
    } else {
      setAddCompanyPopup(false);
    }
  }, []);

  // Selector
  const { GetCompany, companyLoading } = useSelector((store) => ({
    // Company Data
    GetCompany: store?.companiesData?.getCompanyStatus?.data?.data?.data,
    companyLoading: store?.companiesData?.loading,
  }));

  // Get APIs
  useEffect(() => {
    dispatch(getCompany({ page, limit }));
  }, [dispatch, page, limit]);

  const handlePrevClick = () => {
    setPage(page - 1);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  // const handleClose = () => {
  //   setShowModal(false);
  //   setShowChat(false);
  // };

  const handleChat = () => {
    setShowChat(!showChat);
  };

  const closeModal = () => {
    setShowChat(false);
  };

  // const handleChatOpen = () => {
  //   setShowModal(true);
  // };

  const Modal = () => {
    return (
      <>
        <div className="flex fixed bottom-28 right-10">
          <div className="bg-gray-400 rounded-3xl w-10 h-10 flex justify-center items-center shadow-2xl mr-2">
            <User size="30" className="fill-white text-white" />
          </div>
          <div>
            <div className="w-80 bg-secondaryLight p-6 rounded-t-3xl rounded-br-3xl rounded-bl-1xl shadow-xl items-center justify-center flex">
              <div className="w-60">
                <h2 className="font-poppins text-base font-normal">
                  Welcome to our website!
                </h2>
                <p className="font-poppins text-sm font-thin mt-2 text-blue-800">
                  Nice to meet you! If you have any question about our services,
                  feel free to contact us.
                </p>
              </div>
            </div>
            <button
              className="w-80 bg-lightGreenRgb sm:px-2 sm:py:2 px-2 py-1 rounded-xl shadow-xl items-center justify-center flex mt-4"
              onClick={handleChat}
            >
              <span className="font-poppins text-sm font-normal text-blue-800 border-b border-blue-600">
                New Chat
              </span>
            </button>
          </div>
        </div>
        {showChat && (
          <div className="flex items-center justify-center z-50 bg-gray-500 bg-opacity-50">
            <div className="">
              <ChatList closeModal={closeModal} />
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem("hasSeenAddCompanyModal");

    if (GetCompany?.length === 0 && !hasSeenModal) {
      setAddCompanyPopup(true);
      sessionStorage.setItem("hasSeenAddCompanyModal", "true");
    }
  }, [GetCompany]);

  return (
    <>
      <div className="w-full">
        <div className="relative">
          <div className="flex justify-between">
            <div className="flex">
              <img src={welcome} alt="logo" className="w-20 h-20" />
              <div>
                <h6 className="xl:text-lg lg:text-md sm:text-base text-sm font-ebGaramond font-normal mb-1">
                  Welcome Back!
                </h6>
                <p className="xl:text-3xl lg:text-2xl sm:text-xl text-base font-ebGaramond font-normal">
                  Customer Name
                </p>
              </div>
            </div>
            <div className="">
              <button
                className="text-white rounded-full uppercase text-xs  bg-theme flex items-center sm:px-3 sm:py-3 px-3 py-2"
                onClick={() => {
                  setAddCompanyPopup(true);
                }}
              >
                <img
                  src={addCompany}
                  alt="logo"
                  className="sm:w-5 w-4 sm:h-5 h-4"
                />
                <p className="ml-2 sm:text-base text-xs whitespace-nowrap">
                  ADD COMPANY
                </p>
              </button>
            </div>
          </div>

          <div className="mt-2">
            {GetCompany?.length === 0 && showMessage && (
              <div
                className={`flex items-center text-dark_extra_red bg-light_extra_red space-x-2 rounded px-5 py-3 justify-between`}
              >
                <div className="flex">
                  <InfoCircle size="20" className="mr-2" />
                  <span className="font-normal text-sm">
                    It looks like you haven't registered a company with us yet.
                    Start onboarding your company today!
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    className="text-dark_extra_red font-normal text-sm"
                    onClick={() => {
                      setAddCompanyPopup(true);
                    }}
                  >
                    <p className="ml-2">ADD COMPANY</p>
                  </button>
                  <Close
                    className="cursor-pointer"
                    onClick={() => {
                      setShowMessage(false);
                      sessionStorage.setItem("isCompanyPopup1", false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 mt-6">
            {companyLoading ? (
              <div className="xl:col-span-2">
                <RippleLoader />
              </div>
            ) : (
              <>
                {GetCompany?.length ? (
                  <div className="xl:col-span-2 p-2">
                    <div className="w-full">
                      <div className="box-content min-h-36 main-view p-2 mx-0">
                        <Swiper
                          pagination={{ clickable: true }}
                          navigation={false}
                          modules={[Pagination, Navigation]}
                          className="mySwiper"
                          ref={swiperRef}
                        >
                          {GetCompany?.map((company, index) => (
                            <SwiperSlide key={index} className="p-1">
                              <div>
                                <div className="flex items-center">
                                  <p className="text-xl font-ebGaramond font-normal capitalize ml-8">
                                    {company.name}
                                  </p>
                                  <button className="bg-lightGreen ml-2 px-2 rounded-2xl font-poppins text-xs text-white">
                                    Live
                                  </button>
                                </div>
                                <div className="flex items-center">
                                  <img
                                    src={Registration}
                                    alt="Registration logo"
                                    className="object-cover w-6 h-6"
                                  />
                                  <div className="ml-2">
                                    <p className="text-sm font-ebGaramond font-normal text-gray-700">
                                      Registration No.
                                    </p>
                                    <p className="text-sm font-ebGaramond font-normal text-black">
                                      {company.company_id}
                                    </p>
                                  </div>
                                </div>
                                <div className="mt-1 ml-8">
                                  <p className="text-sm font-ebGaramond font-normal text-gray-700">
                                    Incorporation Date
                                  </p>
                                  <p className="text-sm font-ebGaramond font-normal">
                                    {format(
                                      new Date(company.created_at),
                                      "dd/MM/yyyy"
                                    )}
                                  </p>
                                </div>
                                <div className="flex justify-end mt-2">
                                  <Link
                                    // to={`/my-company-information/${company.id}`}
                                    to={"/my-company-information"}
                                    className="border-b border-blue-700 text-blue-700 font-normal text-sm font-ebGaramond"
                                  >
                                    View More
                                  </Link>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                        {GetCompany?.length ? (
                          <div>
                            <div>
                              <button
                                onClick={handlePrevClick}
                                className="swiper-button-prev"
                                disabled={page == 1}
                              >
                                &lt;
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={handleNextClick}
                                className="swiper-button-next"
                                disabled={GetCompany?.length < 10}
                              >
                                &gt;
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            <div className="p-2">
              <div className="box-content min-h-40 main-view p-2 pb-1 mx-0">
                <div>
                  <p className="text-xl font-ebGaramond capitalize font-normal ml-8">
                    Have any questions?
                  </p>
                  <div className="flex">
                    <img
                      src={Question}
                      alt="logo"
                      className="object-cover w-6 h-6 mt-1"
                    />
                    <div className="ml-2">
                      <p className="text-sm font-ebGaramond font-normal text-gray-700">
                        Contact us
                      </p>
                      <p className="text-sm font-ebGaramond font-normal text-black">
                        +65 9999 9999
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-1 ml-8">
                  <p className="text-sm font-ebGaramond font-normal text-gray-700">
                    Email
                  </p>
                  <p className="text-sm font-ebGaramond font-normal">
                    xxx@mail.com
                  </p>
                </div>
                <Link
                  to={"/tickets"}
                  className="border-b border-blue-700 text-blue-700 font-normal text-sm font-ebGaramond absolute right-3"
                >
                  Send us a ticket
                </Link>
              </div>
            </div>
          </div>

          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-0 gap-5 border-t-2 sm:pt-12 pt-5 sm:mt-10 mt-3">
            <div className="box-content min-h-44 main-view p-2">
              <div className="flex mb-2">
                <img
                  src={Notification}
                  alt="logo"
                  className="object-cover w-6 h-6"
                />
                <p className="text-xl font-ebGaramond font-normal ml-2">
                  Bulletin
                </p>
              </div>
              <div className="bg-light_secondary_shades h-36 text-center flex flex-col justify-center rounded-md">
                <div className="justify-center flex">
                  <img src={Check} alt="logo" className="w-6 h-6" />
                </div>
                <p className="">You are all good!</p>
              </div>
            </div>

            <div className="xl:col-span-2">
              <div className="box-content min-h-40 main-view p-2">
                <div className="flex mb-2">
                  <img src={Page} alt="logo" className="w-6 h-6" />
                  <p className="text-xl font-ebGaramond font-normal ml-2">
                    On-Going Task
                  </p>
                </div>
                <div className="bg-light_secondary_shades h-32 text-center flex flex-col justify-center rounded-md">
                  <div className="justify-center flex">
                    <img src={Check} alt="logo" className="w-6 h-6" />
                  </div>
                  <p className="">You are all good!</p>
                </div>
                <div className="flex justify-end ">
                  <Link
                    to={"/on-going-service"}
                    className="border-b border-blue-700 text-blue-700 font-normal text-sm font-ebGaramond"
                  >
                    View More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {addEditRolePopup && (
          <AddCompany setAddCompanyPopup={setAddCompanyPopup} />
        )}

        {/* Chat */}
        {/* <div className="fixed bottom-10 right-10 z-50">
          <button onClick={showModal ? handleClose : handleChatOpen}>
            {showModal ? (
              <CloseCircle size="50" className="fill-lightGreen text-white" />
            ) : (
              <img src={chat} alt="logo" className="h-16 w-16" />
            )}
          </button>
        </div> */}

        {showModal && <Modal />}
      </div>
    </>
  );
};

export default Dashboard;
