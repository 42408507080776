import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/micro";
import { useNavigate } from "react-router-dom";
import {
  HandCreditCard,
  HandCreditCardIcon,
  MasterCardIcon,
} from "../../assets/svg/AllSvg";
import AddNewCard from "./components/AddNewCard";
import Action from "../../commons/Action";
import ConfirmationModel from "../../commons/ConfirmationModel";
import {
  deleteCard,
  getCardList,
  updateCard,
} from "../../redux/billingSubscriptions/slice";
import { useDispatch, useSelector } from "react-redux";
import RippleLoader from "../../commons/RippleLoader";

const MyPaymentMethod = () => {
  const [addNewCard, setAddNewCard] = useState(false);
  const [removeCard, setRemoveCard] = useState(false);
  const [isDefaultCard, setIsDefaultCard] = useState(false);
  const [cardDetails, setCardDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // selector
  const { allCard, loading } = useSelector((store) => ({
    allCard: store?.billingSubscriptionsData?.getCardListStatus?.data?.data,
    loading: store?.billingSubscriptionsData?.loading,
  }));

  useEffect(() => {
    dispatch(getCardList());
  }, [dispatch]);

  // handle Delete
  const handleDelete = () => {
    dispatch(deleteCard(cardDetails?.id)).then((res) => {
      setRemoveCard(false);
      if (res.type === "deleteCard/fulfilled") {
        setCardDetails({});
        dispatch(getCardList());
      }
    });
  };

  // handle Default Card
  const handleDefaultCard = () => {
    dispatch(
      updateCard({
        id: cardDetails?.id,
        data: {
          is_default: cardDetails?.is_default === 1 ? false : true,
        },
      })
    ).then((res) => {
      setIsDefaultCard(false);
      if (res.type === "updateCard/fulfilled") {
        setCardDetails({});
        dispatch(getCardList());
      }
    });
  };

  return (
    <>
      {/* Page Header */}
      <div>
        <BackButton
          onClick={() => navigate("/billing-subscriptions")}
          className={"mb-3"}
        />
        <div className="flex items-center space-x-3 border-b-2 pb-5 mb-5">
          <h1 className="xl:text-3xl lg:text-2xl sm:text-xl text-base font-ebGaramond">
            Payment method
          </h1>
          <div className="max-w-14 w-full">
            <HandCreditCard className="w-full" />
          </div>
        </div>
        {/* Card List */}
        {loading ? (
          <RippleLoader />
        ) : (
          <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5 mb-5">
            {allCard?.data?.map((card, index) => {
              return (
                <div
                  key={index}
                  className={`text-white rounded-xl flex flex-col responsive_base_text justify-between relative ${
                    card?.is_default === 1
                      ? "bg-custom-gradient-1"
                      : "bg-custom-gradient-2"
                  }`}
                >
                  <div className="py-4 px-4">
                    <div className="flex items-center space-x-2">
                      {card?.is_default === 1 ? (
                        <>
                          <span className="w-3 h-3 rounded-full border-2 border-white bg-[#16A88F]"></span>
                          <p>Default Card</p>
                        </>
                      ) : null}
                    </div>
                    <div className="absolute top-3 right-3">
                      <Action
                        optionList={
                          <>
                            <li
                              className="flex gap-2 text-black items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                              onClick={() => {
                                setCardDetails(card);
                                setAddNewCard(true);
                              }}
                            >
                              Edit
                            </li>
                            <li
                              className="flex gap-2 text-black items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                              onClick={() => {
                                setCardDetails(card);
                                setIsDefaultCard(true);
                              }}
                            >
                              {cardDetails?.is_default === 1
                                ? "Remove Default"
                                : "Set as Default"}
                            </li>
                            <li
                              className="flex gap-2 text-black items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                              onClick={() => {
                                setCardDetails(card);
                                setRemoveCard(true);
                              }}
                            >
                              Delete
                            </li>
                          </>
                        }
                        fillOpacity={1}
                        fillClass={"fill-white"}
                      />
                    </div>
                    <p className="py-5">{card?.number}</p>
                  </div>
                  <div className="flex justify-between bg-white/25 py-3 px-4 ">
                    <div className="grid grid-cols-2 uppercase text-sm gap-5">
                      <div>
                        <p className="text-xs opacity-70 mb-1">CARD HOLDER</p>
                        <p>{card?.name}</p>
                      </div>
                      <div>
                        <p className="text-xs opacity-70 mb-1">EXPIRED</p>
                        <p>
                          {card?.month}/{card?.year}
                        </p>
                      </div>
                    </div>
                    <div className="max-w-14 w-full">
                      <MasterCardIcon />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {/* Add New Card */}
        <div className="card border-2 border-dashed !rounded-2xl bg-white flex justify-center !py-16">
          <div className="flex flex-col items-center">
            <HandCreditCardIcon className="max-w-9 mb-3" />
            <button
              className="btn-theme uppercase"
              onClick={() => {
                setCardDetails(null);
                setAddNewCard(true);
              }}
            >
              Add New Card
            </button>
          </div>
        </div>
      </div>
      {/* Add New Card Pop Up */}
      {addNewCard && (
        <AddNewCard
          cardDetails={cardDetails}
          onClose={() => setAddNewCard(false)}
        />
      )}
      {/* Remove Card Confirmation */}
      {removeCard && (
        <ConfirmationModel
          confirmText={"Delete Card?"}
          isLoading={loading}
          onClose={() => setRemoveCard(false)}
          onConfirm={handleDelete}
        />
      )}
      {/* Set Card as Default Confirmation */}
      {isDefaultCard && (
        <ConfirmationModel
          confirmText={
            cardDetails?.is_default === 1
              ? "Remove Default Card"
              : "Set Card as Default"
          }
          isLoading={loading}
          onClose={() => setIsDefaultCard(false)}
          onConfirm={handleDefaultCard}
        />
      )}
    </>
  );
};

export default MyPaymentMethod;
